:root {
  --primary: #6835FA;
  --primary-light: #7C4EFF;
  --primary-dark: #521AF1;
  --secondary: #EA9B24;
  --secondary-light: #F9AD3B;
  --secondary-dark: #E49114;
  --error: #F23434;
  --error-light: #FF6464;
  --error-dark: #EB2121;
  --info: #7ADDC5;
  --info-light: #99F1DC;
  --info-dark: #5FD0B5;
  --success: #51E34E;
  --success-light: #67F564;
  --success-dark: #216F44;
  --black: #101b2f;
  --black-light: #294376;
  --black-lighter: #5F7CB6;
  --white: #dedeed;
  --white-dark: #efeff8;
  --white-darker: #b4b3d1;

  /*--white-box-shadow: 0 0px 4px rgb(255 255 255 / 50%);*/
  --black--lighter-box-shadow: 0 0px 2px rgb(95 124 182 / 50%);

  --opacity: 0.5;

  /*gliese in game colours*/
  --sg-teal:  #468692;
  --sg-yellow:  #f2c359;
  --sg-pale-pink:  #e9a4b7;
  --sg-blue:  #2a3d62;
  --sg-pale-green:  #4da781;
  --sg-white:  #f3f2e0;
  --sg-dark-green:  #1a3f38;
  --sg-pink:  #d4799c;
  --sg-pale-yellow:  #fffbae;
  --sg-pale-blue: #b0ecf6;
  --sg-sunflow-orange:  #ffa300;
  --sg-mauve:  #7e2553;
  --sg-slate:  #676172;
  --sg-grey:  #c2c3c7;
  --sg-sky-blue:  #29adff;
  --sg-green:  #008751;
  --sg-orange:  #f27f41;
  --sg-purple:  #4a5ba0;
  --sg-red:  #a0240b;
  --sg-brown:  #936451;
}

html {
	font-family: 'Inconsolata', monospace;
  font-size: 10px;
  background: #101b2f;
  background: radial-gradient(rgba(22,50,60,0) 0%, rgba(22,50,60,0) 57%, rgba(0,0,0,0.6) 100%);
  background-color: #101b2f;
  font-weight: 600;
}

@view-transition {
  navigation: auto;
}

@media screen and (orientation:portrait) {

  #main {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 1200px) {

  html {
    font-size: 1.4vw;
  }
}


body {
	margin: 0;
  background: #101b2f;
}

h3, p {
  font-size: 3.2rem;
  font-weight: 500;
}

canvas {
	z-index: 1;
}

.highlighting canvas {
  cursor: pointer;
}

#root {
  z-index: 1;
  background-color:  var(--black);
}


.scan-lines {
  position: fixed;
  top: 0;
  z-index: 21232432;
  width: 100vw;
  pointer-events: none;
  height: 100vh;
}

.scan-lines:before {
  display: block;
  pointer-events: none;
  content: "";
  position: absolute;
}

/*.scan-lines:after {
  display: block;
  pointer-events: none;
  content: "";
  position: absolute;
  z-index: 2147483648;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: -webkit-repeating-linear-gradient(top,transparent 0,transparent 3px,rgba(22,50,60,.2) 3px,rgba(22,50,60,.2) 6px);
  -webkit-background-size: 100% 6px;
  background-image: -moz-repeating-linear-gradient(top,transparent 0,transparent 3px,rgba(22,50,60,.2) 3px,rgba(22,50,60,.2) 6px);
  -moz-background-size: 100% 6px;
  background-image: repeating-linear-gradient(top,transparent 0,transparent 3px,rgba(22,50,60,.2) 3px,rgba(22,50,60,.2) 6px);
  background-size: 100% 6px;
  opacity: 0.7;
}*/
.scan-lines:after {
  display: block;
  pointer-events: none;
  content: "";
  position: absolute;
  z-index: 2147483648;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: repeating-linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.2) 1px,  transparent 2px,  transparent 3px);
  opacity: 1;
}

.gradient-overlay {
  pointer-events: none;
  position: fixed;
  width: 100vw;
  background: #101b2f;
  background: radial-gradient(rgba(22,50,60,0) 0%, rgba(22,50,60,0) 57%, rgba(0,0,0,0.6) 100%);
  height: 100vh;
  z-index: 1110;
}

.shortcut {
  color: var(--secondary);
}

.hint {
  color: var(--info);
}

.title {
  color: var(--secondary);
}

.explanation-text {
  color: var(--black-lighter);
}

.error {
  color:  var(--error);
}